<template>
  <!-- 移动端 -->
  <div class="register-mobile-login">
    <el-card class="box-card">
      <div class="header">
        <img src="../../assets/imgs/logo.png" style="height: 100px" alt="" />
        <p>广东省职业健康协会</p>
      </div>
      <div class="container">
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-position="left"
        >
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="VerificationCode">
            <el-input
              v-model="form.VerificationCode"
              maxlength="6"
              placeholder="请输入手机验证码"
            >
              <template v-slot:append>
                <el-button
                  style="background-color: transparent"
                  @click="clickVerificationCodeFun"
                  :loading="loadingVerificationSuccess"
                  :disabled="isverificationCodeCountDownt"
                  >{{ verificationCodeText }}</el-button
                >
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div v-if="isShowVerification">
          <slideVerifyDiv @onSuccess="onSuccess"></slideVerifyDiv>
        </div>
        <div class="footer">
          <el-button
            type="primary"
            round
            @click="confirm"
            :loading="loading"
            style="margin: 30px 0; width: 100%"
          >
            注册</el-button
          >
        </div>
      </div>
    </el-card>
    <div class="register-mobile-login-tipsText">登录遇到问题？</div>
  </div>
</template>
<script>
import slideVerifyDiv from "../../components/slideVerify";
export default {
  name: "register",
  data() {
    return {
      verificationCodeText: "发送验证码", // 发送验证码文本
      verificationCodeCountDownt: null, // 发送验证码倒计时
      isverificationCodeCountDownt: false, // 是否在倒计时
      isShowVerification: false, // 是否显示滑动验证模块
      isVerificationSuccess: false, // 是否验证成功
      loadingVerificationSuccess: false, // 是否请求验证数据
      isSendOut: false, // 是否发送过验证码
      form: {
        phone: "",
        VerificationCode: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        VerificationCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    _isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
  },
  created() {},
  methods: {
    clickVerificationCodeFun() {
      if (!this.isShowVerification) {
        // 验证手机格式是否正确
        this.$refs.ruleForm.validateField("phone", (valid) => {
          if (!valid) {
            this.isShowVerification = true;
          }
        });
      }
    },
    onSuccess() {
      this.isVerificationSuccess = true;
      this.loadingVerificationSuccess = true;
      this.getVerificationCode();
    },
    // 发送请求
    getVerificationCode() {
      this.isSendOut = false;
      this.$https
        .post("/api/sendVerifyCode", {
          mobile: this.form?.phone,
        })
        .then((res) => {
          this.$message.success("发送成功");
          this.loadingVerificationSuccess = false;
          if (res.success) {
            this.isSendOut = true;
            this.countDown();
          } else {
            this.isShowVerification = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error(error.message);
          // this.$Toast('发送失败');
          this.isShowVerification = false;
          this.loadingVerificationSuccess = false;
        });
    },
    // 倒计时
    countDown() {
      let maxTime = 60;
      if (this.verificationCodeCountDownt) {
        clearInterval(this.verificationCodeCountDownt);
      }
      this.verificationCodeCountDownt = setInterval(() => {
        if (maxTime <= 0) {
          this.verificationCodeText = "重新发送";
          this.isverificationCodeCountDownt = false;
        } else {
          this.isShowVerification = false;
          this.isverificationCodeCountDownt = true;
          this.verificationCodeText = maxTime + "S";
        }
        maxTime--;
      }, 1000);
    },
    // 表单认证
    confirm() {
      if (!this.isSendOut) {
        this.$Toast("请先获取验证码");
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(valid);
          this.loading = true;
          this.$https
            .post("/api/verifyCode", {
              mobile: this.form.phone,
              verifyCode: this.form.VerificationCode,
            })
            .then((res) => {
              this.loading = false;
              if (res.success) {
                this.$store.commit("setPhone", this.form.phone);
                this.$store.commit(
                  "setVerificationCode",
                  this.form.VerificationCode
                );
                this.$store.commit("setUser", res?.data);
                this.$storage.setStorage([
                  {
                    key: "userInfo",
                    value: res?.data,
                  },
                ]);
                this.$storage.setCookie("token", res?.data?.token);
                this.$router.push({
                  path: "/registerSignUp",
                  query: {
                    phone: this.form.phone,
                    code: this.form.VerificationCode,
                  },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error(error.message);
              // this.$Toast('发送失败');
              this.isShowVerification = false;
              this.loadingVerificationSuccess = false;
            });
        } else {
          return false;
        }
      });
    },
  },
  components: {
    slideVerifyDiv,
  },
};
</script>
<style lang="less" scope>
// 移动端
.register-mobile-login {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 50px 40px;
  overflow: auto;
  .box-card {
    width: 100%;
    max-width: 420px;
    padding: 30px 0;
    margin: 0 auto;
  }
  .el-form-item {
    margin-bottom: 25px;
  }
  .header {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #677897;
    font-weight: bold;
  }
  .container {
    padding: 20px 30px 20px 30px;
  }
  .footer {
    text-align: center;
  }
  .register-mobile-login-tipsText {
    text-align: center;
    padding: 15px 0;
  }
}

.pc-login {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-image: url(../../assets/imgs/background.png);
  .login-box {
    position: absolute;
    width: 400px;
    height: 50%;
    top: 20%;
    right: 10%;
    .box-card {
      padding: 20px;
      background: #f6f9fd;
      .header {
        margin-top: 5%;
        text-align: center;
        img {
          width: 200px;
        }
      }
      .content {
        margin-top: 1%;
        position: relative;
        .set {
          cursor: pointer;
          position: absolute;
          left: 10px;
          color: #b0b9ca;
          line-height: 15px;
          font-size: 12px;
          border-bottom: 1px solid #b0b9ca;
        }
        .reset {
          cursor: pointer;
          position: absolute;
          right: 10px;
          color: #b0b9ca;
          line-height: 15px;
          font-size: 12px;
          border-bottom: 1px solid #b0b9ca;
        }
      }
      .footer {
        padding: 5% 0;
        .login {
          cursor: pointer;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}

.dialag-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #677897;
}

.confirm {
  margin-top: 5%;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

// 覆盖element样式
.el-dialog {
  border-radius: 5px !important;
}
.el-dialog__header {
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__body {
  padding: 0 20px 30px 20px !important;
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__title {
  color: #677897 !important;
}
.el-form--label-top .el-form-item__label {
  color: #677897;
  padding: 0;
}
.el-form-item {
  margin-bottom: 10px;
}
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
</style>
